/** @jsxImportSource @emotion/react */
import { useTheme, css } from '@emotion/react'

import { Theme } from '../theme'

type Callback = (theme: Theme) => any

export const useStyles = (cb: Callback) => {
  const theme = useTheme() as any
  return css(cb(theme as any))
}
