import React, { FC } from 'react'
import { useField, useFormikContext } from 'formik'
import { Label, Input, FormFeedback, InputGroup, InputGroupAddon } from 'reactstrap'

export const QuantityField: FC<{
  name: string
  label: string
  unit: string
  max?: number
  type?: 'text' | 'number'
  size?: 'sm' | 'lg'
  disabled?: boolean
}> = ({ name, type = 'number', max, label, unit, disabled = false, size, ...props }) => {
  const { isSubmitting } = useFormikContext()
  const [{ onChange, value, ...field }, { error, touched }, { setTouched }] = useField(name)

  return (
    <div {...props}>
      <Label size={size} for={name}>
        {label}
      </Label>

    <InputGroup>
      <Input
        id={name}
        bsSize={size}
        type={type}
        value={value || ''}
        invalid={!!error && !!touched}
        disabled={disabled || isSubmitting}
        max={max}
        onChange={(x) => {
          onChange(x)
          setTouched(true)
        }}
        {...field}
      />
        <InputGroupAddon addonType="append">{unit}</InputGroupAddon>
        </InputGroup>

      {error && touched && <FormFeedback>{error}</FormFeedback>}
    </div>
  )
}
