import moment from 'moment'

export const calculateStartEndDates = (year, codeType) => {
  let month = 0
  let length = 12

  if (codeType === 'WINTER') {
    return {
      startDate: moment({
        year: year,
        month: 9,
        day: 1,
      }),
      endDate: moment({
        year: year + 1,
        month: 2,
      }).endOf('month'),
    }
  }

  if (codeType === 'SUMMER') {
    month = 3
    length = 6
  } else if (codeType && codeType[0] === 'M') {
    month = parseInt(codeType.slice(1), 10) - 1
    length = 1
  } else if (codeType && codeType[0] === 'Q') {
    month = (parseInt(codeType.slice(1), 10) - 1) * 3
    length = 3
  }

  return {
    startDate: moment({
      year,
      month,
      day: 1,
    }),
    endDate: moment({
      year,
      month: month + length - 1,
    }).endOf('month'),
  }
}

export const getNbMonthsInPeriod = (start, end) => {
  return Math.round(moment(end).diff(moment(start), 'months', true))
}