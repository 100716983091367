import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Button } from 'reactstrap'

import CurrentSelection from './CurrentSelection'

const SidebarCurrent = ({ searchPanelOpen, toggleSearchPanel }) => {
  const { t } = useTranslation()

  return (
    <div className="current-selection-wrapper">
      <Row className="current-selection-header">
        <Col>
          <Button
            className="w-100 toggle-panel glow"
            style={{
              backgroundColor: '#0082f3cc',
              borderColor: 'rgb(8 176 77 / 64%)',
            }}
            onClick={() => toggleSearchPanel()}
            active={searchPanelOpen}
          >
            {searchPanelOpen ? t('sidebar.panel.close') : t('sidebar.panel.open')}
          </Button>
        </Col>
      </Row>

      <Row className="current-selection-content">
        <Col>
          <CurrentSelection />
        </Col>
      </Row>
    </div>
  )
}

export default SidebarCurrent
