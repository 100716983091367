/** @jsxImportSource @emotion/react */
import { useCallback, useState, useEffect } from 'react'
import { Form, Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { SelectField, CheckboxField, DateField, QuantityField } from 'components/common/form'
import { ArenhQuantityInput } from './ArenhQuantityInput'
import { isNil } from 'lodash'
import { isReadOnlyUser } from 'helpers/userRole'

export const DealsCreateForm = ({ isLoading, onDelete, isValidationTab, ...props }) => {
  if(props.values == null)
    return null

  const { t } = useTranslation()
  const theme = useTheme()
  const isReadOnly = isReadOnlyUser()
  const showDelete = !isReadOnly && !isValidationTab

  const [transactionQuotationId, setTransactionQuotationId] = useState(0)
  const [selectedQuotation, setSelectedQuotation] = useState(null)

  const options = [{"value" : "", "label": ""}, ...props.values.QuotationOptions.map((q) => {
    const {
      TypeCotation,
      Cotation: { id, CotProduit },
    } = q
    return {
      value: TypeCotation === 1 ? id : `${id}_${TypeCotation}`,
      label: CotProduit.PdtNom,
    }
  })]

  const isDisabled = props.values.Transaction.validated || isLoading || props.isSubmitting || Object.keys(props.touched).length === 0 
  const hasError = Object.keys(props.errors).length > 0

  const getQuotation = (value) => {
    if(isNil(value))
      return null
    const [id, type = 1] = value.toString().split('_').map(Number)
    return props.values.QuotationOptions.find((q) => q.Cotation.id === id && q.TypeCotation === type)
  }

  const getQuotationPeak = (value) => {
    if(isNil(value))
      return null
    const [id, type = 1] = value.toString().split('_').map(Number)
    return props.values.QuotationOptions.find((q) => q.IdCotationPeak === id && q.TypeCotation === type)
  }
  
  const onChangeTransactionQuotationId = (value) => {
    const q = getQuotation(value)
    setTransactionQuotationId(value)
    setSelectedQuotation(q)
    props.values.Transaction.TypePtf =  q?.PtfType
    props.values.Transaction.idCotation = q?.Cotation.id
    props.values.Transaction.idCotationPeak = q?.IdCotationPeak
  }
  
  const selectedTransaction = isNil(props.values.Transaction.id) ? null : props.values.Transaction
  const parcel = props.values.ContractParcels.find((p) => p.id === props.values.SelectedParcelId)
  const isArenh = parcel.IsArenh

  useEffect(() => {
    if(selectedQuotation == null && !isNil(selectedTransaction)){
      let q = getQuotation(selectedTransaction?.idCotation)
      if (isNil(q))
        q = getQuotationPeak(selectedTransaction?.idCotation)
  
      setSelectedQuotation(q)
    }
  }, [selectedQuotation, selectedTransaction])

  const toggle = useCallback(() => {
    if(props.values.Transaction.HasPeak == false || isNil(props.values.Transaction.HasPeak)){
      props.values.Transaction.HasPeak = true
      const q = getQuotationPeak(selectedTransaction?.idCotation ?? selectedQuotation.IdCotationPeak)
      props.values.Transaction.idCotationPeak = q?.IdCotationPeak
    }
    else{
      props.values.Transaction.HasPeak = false
    }
  })

  const checkboxOption = [{label: selectedQuotation?.Cotation.CotProduit.PdtNomPeak, value:props.values.Transaction.HasPeak}]

  return (
    <Form
      onSubmit={props.handleSubmit}
      css={{
        section: {
          display: 'grid',
          gridGap: 10,
          gridTemplateColumns: 'repeat(2, 1fr)',
          paddingTop: theme.spacing,
          paddingBottom: theme.spacing * 2,
        },
        footer: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '>*': {
            display: 'flex',
            justifyContent: 'flex-end',
            button: { marginLeft: '0.6rem' },
          },
        },
      }}
    >
      <section>
        <SelectField
          label={t('execution.transactionPopover.quotation')}
          options={options}
          name="Transaction.idCotation"
          onChange={onChangeTransactionQuotationId}
          value={transactionQuotationId == 0 ? selectedQuotation?.Cotation.id : transactionQuotationId}
          disabled={props.values.Transaction.validated}
        />
        {isArenh ? (
          <ArenhQuantityInput
            id="Transaction.TrsVolume"
            name="Transaction.TrsVolume"
            unit={selectedQuotation?.UniteVolDeal}
            max={selectedQuotation && selectedQuotation.TypeCotation === 3 ? -1 : 100}
            contractId={props.values.ContractId}
            parcelId={parcel.id}
            label={t('execution.transactionPopover.quantity')}
            ptfType={selectedQuotation?.PtfType}
            disabled={props.values.Transaction.validated}
          />
        ) : (
        <QuantityField
          name = "Transaction.TrsVolume"
          id={t('Quantity')}
          unit={selectedQuotation?.UniteVolDeal}
          label={t('execution.transactionPopover.quantity')}
          max={selectedQuotation && selectedQuotation.TypeCotation === 3 ? -1 : 100}
          disabled={props.values.Transaction.validated}
          />
        )}
        <DateField
          name="Transaction.TrsDate"
          label={t('execution.transactionPopover.date')}
          inputProps={{disabled: props.values.Transaction.validated} }
          disabled={props.values.Transaction.validated}
        />   
        <QuantityField name="Transaction.TrsPrix" label={t('execution.transactionPopover.price')} unit={selectedQuotation?.UnitePrixDeal} disabled={props.values.Transaction.validated}/>
      </section>
      <footer>
        <div>
          {(showDelete) && 
          <Button color="danger" onClick={onDelete} disabled={hasError}>
            {t('strategy.alertPopover.delete')}
          </Button>
          }
        
          {(!isReadOnly) && 
            <Button type="submit" disabled={isDisabled || hasError}>
              {isLoading && <Spinner size="sm" style={{ marginRight: '.6rem' }} />}
              <span>{t('strategy.alertPopover.save')}</span>
            </Button>
          }
        </div>
      </footer>
    </Form>
  )
}
