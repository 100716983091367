import { superFetch } from 'commons'

export const fetchContractParcels = (contractId, debut, fin) => {
  let url = `contrats/${contractId}/lots?type=withdeals`
  if (debut && fin) {
    const strDebut = debut.format('YYYY-MM-DD')
    const strFin = fin.format('YYYY-MM-DD')
    url = `${url}&debut=${strDebut}&fin=${strFin}`
  }
  return superFetch({ url: url })
}

export const fetchContractParcelDetail = (contractId, parcelId) => {
  return superFetch({ url: `contrats/${contractId}/lots/${parcelId}` })
}

export const fetchParcelTarifs = (parcelId) => {
  return superFetch({ url: `lots/${parcelId}/tarif` })
}
