import { isAdmin as getIsAdminUser, isQualityUser as getIsQualityUser } from 'selectors/user'
import { useSelector } from 'react-redux'

export const isAdminRole = () => {
    const isAdminUser = useSelector(getIsAdminUser)
    return isAdminUser
}

export const isQualityUserRole = () => {
    const isQualityUser = useSelector(getIsQualityUser)
    return isQualityUser
}

export const isReadOnlyUser = () => {
    const isQualityUser = useSelector(getIsQualityUser)
    const isAdminUser = useSelector(getIsAdminUser)
    const isReadOnlyUser = !isAdminUser && !isQualityUser
    return isReadOnlyUser
}