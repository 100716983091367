import { superFetch } from 'commons'

export const fetchContractTransactions = (contractId, quotationId) => {
  let url = `contrats/${contractId}/transaction`
  if (quotationId) url = `${url}?idCotation=${quotationId}`

  return superFetch({ url: url })
}

export const fetchContractsTransactions = (contractIds, startDate, endDate) => {
  const query = {
    debut: typeof startDate === 'string' ? startDate : startDate.format('DD/MM/YYYY'),
    fin: typeof endDate === 'string' ? endDate : endDate.format('DD/MM/YYYY'),
    contrats: contractIds,
  }

  return superFetch({
    url: 'contrats/transaction',
    method: 'POST',
    body: query,
  })
}

export const fetchClientTransactions = () => {
  return superFetch({ url: `organisations/gazelenergie/deals` })
}

export const fetchLotTransactions = (lotId) => {
  return superFetch({ url: `lot/${lotId}/transaction` })
}

export const fetchTransaction = (lotId, transactionId) => {
  return superFetch({url : `lot/${lotId}/transaction/${transactionId}`})
}

export const fetchLotPeriodeTransactions = (lotId, startDate, endDate) => {
  let url = `lot/${lotId}/transaction/period`

  if (startDate && endDate) {
    const strDebut = startDate.format('YYYY-MM-DD')
    const strFin = endDate.format('YYYY-MM-DD')
    url = `${url}?debut=${strDebut}&fin=${strFin}`
  }

  return superFetch({ url: url })
}

export const fetchLotQuotationTransactions = (lotId, cotId) => {
  return superFetch({ url: `lot/${lotId}/cotation/${cotId}/transaction` })
}

export const addTransaction = (lotId, transaction) => {
  return superFetch({
    url: `lot/${lotId}/transaction`,
    method: 'POST',
    body: transaction,
  })
}

export const updateTransaction = (lotId, transaction) => {
  return superFetch({
    url: `lot/${lotId}/transaction/${transaction.id}`,
    method: 'PUT',
    body: transaction,
  })
}

export const deleteTransaction = (lotId, transactionId) => {
  const url = `lot/${lotId}/transaction/${transactionId}`
  return superFetch({ url: url, method: 'DELETE' })
}


export const validateTransaction = (transactionId) => {
  return superFetch({
    url: `ordreValidation/${transactionId}`,
    method: 'PUT',
  })
}

export const getTransactionComments = (transactionId) => {
  return superFetch({url: `transaction/${transactionId}/comments`})
}

export const createTransactionComment = (comment) => {
  return superFetch({
    url: `transaction/comments`,
    method: 'POST',
    body: comment,
  })
}

export const updateTransactionComment= (comment) => {
  return superFetch({
    url: `transaction/comments`,
    method: 'PUT',
    body: comment,
  })
}

export const deleteTransactionComments = (commentId) => {
  const url = `transaction/comments/${commentId}`
  return superFetch({ url: url, method: 'DELETE' })
}

export const createClientValidation = (validation) => {
  return superFetch({
    url: `transaction/${validation.TransactionId}/validation/client`,
    method: 'POST',
    body: validation,
  })
}

export const updateClientValidation = (validation) => {
  return superFetch({
    url: `transaction/${validation.TransactionId}/validation/client`,
    method: 'PUT',
    body: validation,
  })
}

export const createInternalValidation = (validation) => {
  return superFetch({
    url: `transaction/${validation.TransactionId}/validation/internal`,
    method: 'POST',
    body: validation,
  })
}

export const updateInternalValidation = (validation) => {
  return superFetch({
    url: `transaction/${validation.TransactionId}/validation/internal`,
    method: 'PUT',
    body: validation,
  })
}