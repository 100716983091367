import React, { FC } from 'react'
import { Label, Input, FormFeedback } from 'reactstrap'
import { useTranslation } from 'react-i18next'

export interface SelectOption {
  value: string | number
  label: string | React.ReactElement
}

export const Select: FC<any> = ({
  value,
  onChange,
  label,
  disabled = false,
  invalid = false,
  innerProps,
  error,
  all = true,
  id = 'supplier',
  size,
  options,
  inline = false,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <div
      css={{
        ...(inline && {
          display: 'flex',
          alignItems: 'center',
          label: { marginRight: '0.6rem', marginBottom: 0 },
        }),
      }}
      {...props}
    >
      <Label for={id} size={size} css={{ padding: 0 }}>
        {label}
      </Label>

      <Input
        id={id}
        type="select"
        bsSize={size}
        value={value || ''}
        onChange={(e) => {
          const value = e.target.value
          onChange(value === '' ? null : e.target.value)
        }}
        disabled={disabled}
        invalid={invalid}
        {...innerProps}
      >
        {all && <option value="">{t('global.all')}</option>}
        {options.map(({ value, label }: any, index: number) => (
          <option key={`${value}${index}`} value={value}>
            {label}
          </option>
        ))}
      </Input>
      {error && <FormFeedback>{error}</FormFeedback>}
    </div>
  )
}
