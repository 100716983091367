import React, { FC } from 'react'
import { useField, useFormikContext } from 'formik'
import { Label, Input, FormFeedback } from 'reactstrap'
import moment, { Moment } from 'moment'
import DateTime from 'react-datetime'
import { dateFormat } from 'helpers/formater'

export const DateField: FC<{
  name: string
  label: string
  inputProps?: any
  size?: 'sm' | 'lg'
  disabled?: boolean
}> = ({ name, label, size, disabled, inputProps, ...props }) => {
  const { isSubmitting, validateForm } = useFormikContext()
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name)

  const [inputValue, setInputValue] = React.useState<string | null>(null)

  const format = dateFormat()

  React.useEffect(() => {
    const date = moment(value)
    const dateValue = date.isValid() ? date.format(format) : ''
    setInputValue(dateValue)
  }, [format, value])

  if (inputValue === null) return null

  return (
    <div {...props}>
      <Label size={size} for={name}>
        {label}
      </Label>

      <DateTime
        dateFormat={format}
        timeFormat={false}
        utc={true}
        closeOnSelect={true}
        value={inputValue}
        onChange={(date: Moment | string) => {
          if (typeof date === 'string') {
            setInputValue(date)
            setValue(date)
          } else {
            setInputValue(date.format(format))
            setValue(date.format())

            setTimeout(() => {
              setTouched(true)
              validateForm()
            }, 400)
          }
        }}
        inputProps={inputProps}
      />
    </div>
  )
}
