/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import { Form, Button, Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { SelectField } from 'components/common/form'
import { isAdmin as getIsAdminUser, isQualityUser as getIsQualityUser } from 'selectors/user'
import { useSelector } from 'react-redux'

export const DealsValidationForm = ({ isLoading, onDelete, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const isDisabled = isLoading || props.isSubmitting || Object.keys(props.touched).length === 0
  const hasError = Object.keys(props.errors).length > 0
  const isQualityUser = useSelector(getIsQualityUser)
  const isAdminUser = useSelector(getIsAdminUser)
  const isInternalValdationAllowed = isQualityUser
  const isClientValidationAllowed = !isAdminUser && !isQualityUser

  const clientModified = React.useCallback((value) => {
    props.values.ClientValidation.Changed = true
    props.values.ClientValidation.Status = Number(value)
  })
  
  const internalModified = React.useCallback((value) => {
    props.values.InternalValidation.Changed = true
    props.values.InternalValidation.Status = Number(value)
  })

  const validationOptions = [{label: "", value:""},
  {label: t('execution.transactionStatus.pending'), value:1},
  {label: t('execution.transactionStatus.validated'), value:2},
  {label: t('execution.transactionStatus.invalidated'), value:3}]
  
  return (
    <Form
      onSubmit={props.handleSubmit}
      css={{
        section: {
          display: 'grid',
          gridGap: 10,
          gridTemplateColumns: 'repeat(2, 1fr)',
          paddingTop: theme.spacing,
          paddingBottom: theme.spacing * 2,
        },
        footer: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '>*': {
            display: 'flex',
            justifyContent: 'flex-end',
            button: { marginLeft: '0.6rem' },
          },
        },
      }}
    >
      <hr />
      <section className='mt-4'>
      <SelectField
          label={t('execution.transactionView.internalValid')}
          options={validationOptions}
          name="InternalValidation.Status"
          disabled={!isInternalValdationAllowed}
          onChangeCustom={internalModified}
        />
        <SelectField
          label={t('execution.transactionView.clientValid')}
          options={validationOptions}
          name="ClientValidation.Status"
          disabled={!isClientValidationAllowed}
          onChangeCustom={clientModified}
        />
      </section>
      <footer>
        <div>
          <Button type="submit" disabled={isDisabled || hasError}>
            {isLoading && <Spinner size="sm" style={{ marginRight: '.6rem' }} />}
            <span>{t('strategy.alertPopover.save')}</span>
          </Button>
        </div>
      </footer>
    </Form>
  )
}
