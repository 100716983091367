import React from 'react'
import ReactSelect from 'react-select'

const selectColor = '#31acea'
const focusColor = '#d7dee4'
const disabledColor = '#e9ecef'

const Select = ({ fontSize = 14, invalid, color = 'light', bordered = false, ...props }) => {
  return (
    <ReactSelect
      placeholder={props.isLoading ? 'Chargement...' : props.placeholder || 'Sélectionnez...'}
      styles={{
        container: (provided) => ({
          ...provided,
          fontSize: fontSize,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: props.width || 'unset',
          display: 'inline-block',
        }),
        control: (provided, state) => ({
          ...provided,
          backgroundColor: color === 'light' && (props.isDisabled == false || props.isDisabled == null) ? '#fff' : (props.isDisabled === true ? disabledColor : selectColor),
          color: '#fff',
          minHeight: 'initial',
          borderWidth: bordered ? 1 : 0,
          borderRadius: bordered ? '.25rem' : 0,
          borderColor: invalid ? '#f00' : '#d7dee4',
          // boxShadow: state.isFocused && !invalid ? `0 0 0 1px ${selectColor}` : 'unset',
          boxShadow: 'none',
          paddingTop: 1,
          paddingBottom: bordered ? 2 : 0,
          paddingLeft: 4,
          ':hover': {
            borderColor: invalid ? '#f00' : selectColor,
          },
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0 6px',
          color: color === 'light' ? '#61687f' : '#fff',
          ':hover': {
            color: color === 'light' ? '#61687f' : '#fff',
          },
        }),
        input: (provided) => ({
          ...provided,
          color: color === 'light' ? '#61687f' : '#fff',
        }),
        menu: (provided) => ({
          ...provided,
          //marginTop: 1,
          borderRadius: 0,
          zIndex: 100,
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
        }),
        singleValue: (provided) => ({
          ...provided,
          color: color === 'light' ? '#61687f' : '#fff',
        }),
        multiValue: (provided) => ({
          ...provided,
          color: color === 'light' ? '#61687f' : '#fff',
        }),
        option: (provided, state) => ({
          ...provided,
          padding: '6px 10px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ':active': {
            background: selectColor,
            color: '#fff',
          },
          ...(!state.isFocused
            ? {}
            : {
              background: focusColor,
              color: 'unset',
            }),
          ...(!state.isSelected
            ? {}
            : {
              background: selectColor,
              color: '#fff',
            }),
        }),
      }}
      {...props}
    />
  )
}

export default Select
