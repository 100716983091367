import { useTranslation } from 'react-i18next'
import { QuantityField } from '../../common/form'
import { useQuery } from 'react-query'
import get from 'lodash/get'
import cond from 'lodash/cond'
import { fetchContractArenh } from 'api/contracts'
import { decimalFormat } from 'helpers/formater'
 
export const ArenhQuantityInput = ({ contractId, parcelId, value, onChange, label, max, id, unit, ptfType, disabled }) => {
    const { t } = useTranslation()
    const { data, isLoading, error } = useQuery(['arenh', { contractId, parcelId }], () =>
      fetchContractArenh({ contractId, parcelId }),
    )
    const droitArenh = get(data, 'DroitArenh', null)
    const droitArenhUnit = get(data, 'UniteVolArenh', null)
  
    const message = cond([
      [() => ptfType !== 3, () => ''],
      [() => error, () => t('global.error')],
      [(x) => x === null, () => null],
      [
        (x) => x === -1,
        () => `(${t('arenh.droitArenh')} : ${t('arenh.toBeConfigured').toLowerCase()})`,
      ],
      [
        (x) => x > 0,
        () => `(${t('arenh.droitArenh')} : ${decimalFormat(droitArenh)} ${droitArenhUnit})`,
      ],
    ])(droitArenh)
  
    return (
        <QuantityField
          name = {id}
          id={id}
          unit={unit}
          label={label}
          value={value}
          max={[null, -1].includes(droitArenh) ? max : Math.min(droitArenh, max)}
          disabled={disabled}
        />
    )
  }
