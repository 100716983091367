import React, { useCallback, useState, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getVisibleContractsObjets } from 'reducers/contracts'
import { getContractParcels } from 'reducers/parcels'
import { getLotQuotationsForDealsWithoutState } from 'reducers/quotations'
import { getLotTransactionsWithoutState } from 'reducers/transactions'
import { Dialog } from 'components/common/Dialog'
import { ErrorBoundary } from 'react-error-boundary'
import { Loader } from 'components/common/Loader'
import { DealsCreateDialog } from 'components/deals/DealCreateForm/DealsCreateDialog'

import { fetchContractParcels } from 'actions/parcels'
import { fetchLotQuotationsForDeals } from 'actions/quotations'
import { fetchLotTransactions } from 'actions/transactions'
import { calculateStartEndDates }from 'helpers/dateHelper'

const Transaction = (props) => {
    if(props.year == null)
        return null

    const year = props.year
    const contractId = props.contractId
    const transactionId = props.transactionId
    const lotId = props.lotId

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [selectedTransaction, setSelectedTransaction] = useState(null)
    const [startAndEndDate, setStartAndEndDate] = useState(null)
    const [selectedParcel, setSelectedParcel] = useState(null)

    const contractObjects = useSelector(getVisibleContractsObjets)
    const contractParcels = useSelector(getContractParcels(contractId))
    const lotQuotations = useSelector(getLotQuotationsForDealsWithoutState(selectedParcel?.id))
    const transactions = useSelector(getLotTransactionsWithoutState(selectedParcel?.id))
    
    React.useEffect(() => {
        if(startAndEndDate && contractId){
            dispatch(fetchContractParcels(contractId, startAndEndDate?.startDate, startAndEndDate?.endDate))
        }
    }, [contractId, startAndEndDate])

    React.useEffect(()=> {
        if(selectedParcel && startAndEndDate){
            dispatch(
                fetchLotQuotationsForDeals(
                selectedParcel.id,
                startAndEndDate.startDate,
                startAndEndDate.endDate,
                ))
        }
    }, [selectedParcel, startAndEndDate])

    React.useEffect(()=> {
        if(selectedParcel){
            dispatch(
                fetchLotTransactions(
                selectedParcel.id
                ))
        }
    }, [selectedParcel])

    React.useEffect(()=> {
        if(selectedParcel && startAndEndDate){
            dispatch(
                fetchLotQuotationsForDeals(
                selectedParcel.id,
                startAndEndDate.startDate,
                startAndEndDate.endDate,
                ))
        }
    }, [selectedParcel, startAndEndDate])

    // Select first parcel
    React.useEffect(() => {
        if (lotId){
            const selected = contractParcels.find((parcel) => parcel.id === lotId)
            setSelectedParcel(selected)
        }
        
        if (selectedParcel === null && lotId === null && contractParcels.length > 0) {
            setSelectedParcel(contractParcels[0])
        }
    }, [contractParcels, lotId, selectedParcel])

    React.useEffect(() => {
        const codeType = contractObjects.reduce((acc, contract) => {
            if (acc === 'M' && contract.CtrGranulariteMinProfil !== 'M') {
              return contract.CtrGranulariteMinProfil
            } else if (acc !== 'Y' && contract.CtrGranulariteMinProfil === 'Y') {
              return 'Y'
            } else {
              return acc
            }
          }, 'M')

          const dates = calculateStartEndDates(year, codeType)
          setStartAndEndDate(dates)
      }, [year, contractObjects])

      React.useEffect(() => {
        if(transactions && transactionId){
            const selectedTransaction = transactions.find((t) => t.id == transactionId)
            setSelectedTransaction(selectedTransaction)
        }
      }, [transactions, transactionId])

    return (
        <>
        {props.isModalOpen && (
        <Dialog isOpen={props.isModalOpen} close={props.close} aria-labelledby="new-contract">
          <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <h5 id="new-contract">{t('execution.addDeal')}</h5>
            {props.isModalOpen &&
              <DealsCreateDialog
                transaction={selectedTransaction}
                quotations = {lotQuotations}
                contractId = {contractId}
                contractParcels = {contractParcels}
                selectedParcelId = {selectedParcel?.id}
                done={props.close}
                fetchNewData={props.fetchNewData}
                isValidationTab={props.isValidationTab}
                />
              }
          </Suspense>
          </ErrorBoundary>
        </Dialog>
        )}
        </>
      )
}

export default Transaction
