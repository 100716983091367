import { Form, Button, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { TextField } from '../../common/form/TextField'
import React, {} from 'react'
import { fetchTransactionComments } from 'actions/transactions'
import { useSelector, useDispatch } from 'react-redux'
import ReactTable from 'react-table'
import { translations } from 'helpers/react-table'
import { TableSpinner } from 'components/common/Spinner'
import { deleteTransactionComment } from 'actions/transactions'
import { getTransactionComments } from 'reducers/transactions'
import moment from 'moment'
import { dateFormat } from 'helpers/formater'
import {  userDetail } from 'commons/store/user'

const headerStyle = {
  textTransform: 'uppercase',
  }
  
const columnLeft = {
  headerStyle,
}
  
const columnRight = {
  headerStyle,
  Cell: (row) => <div style={{ textAlign: 'right' }}>{row.value}</div>,
}

export const DealCommentForm = ({ isLoading, transactionId, comments, onChange, ...props }) => {
  if(props.values == null)
    return null
    
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useDispatch()
  const userInfo = useSelector(userDetail)

  const isDisabled = isLoading || props.isSubmitting || Object.keys(props.touched).length === 0
  const hasError = Object.keys(props.errors).length > 0

  React.useEffect(() => {
    dispatch(fetchTransactionComments(transactionId))
  }, [dispatch, transactionId])
  props.values.Comments = useSelector((state) => getTransactionComments(state, transactionId))

  const comment = comments == null ?  props.values.Comments :  comments

  const columns = [
    {
      Header: t('execution.dealPopOver.writer'),
      accessor: 'Writer',
      width: 150,
      ...columnLeft,
    },
    {
      Header: t('execution.dealPopOver.writtenAt'),
      accessor: 'WrittenAt',
      width: 200,
      ...columnLeft,
      filterable: false,
      sortable: true,
      Cell: (row) => <div style={{ textAlign: 'center' }}>{moment(row.original.WrittenAt).format('LLL')}</div>,
    },
    {
      Header: t('execution.dealPopOver.content'),
      accessor: 'Content',
      ...columnLeft,
      sortable: false,
      filterable: true,
      style: { 'whiteSpace': 'unset' } 
    },
    {
      Header: null,
      accessor: 'delete',
      width: 30,
      headerStyle,
      filterable: false,
      sortable: false,
      ...columnRight,
      Cell: (row) => {
        return (
          row.original.Writer == userInfo.UsrLogin && (
          <Button
          className="btn-table active"
          onClick={async () => {
            await dispatch(
            deleteTransactionComment(
                row.original.Id,
                t('execution.confirmDeleteDealComment'),
              ),
            )
            onChange()
          }}
          
        >
          <i className="icon-ico-trash" />
        </Button>
        ))
      },
    },
  ]

  return (
    <Form
      onSubmit={props.handleSubmit}
      css={{
        section: {
          display: 'grid',
          gridGap: theme.spacing,
          gridTemplateColumns: 'repeat(2, 1fr)',
          paddingTop: theme.spacing,
          paddingBottom: theme.spacing * 2,
        },
        footer: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '>*': {
            display: 'flex',
            justifyContent: 'flex-end',
            button: { marginLeft: '0.6rem' },
          },
        },
      }}
    >
      <section>
        <Row>
          <Col><TextField name="Comment.Content" label={t('execution.dealPopOver.comment')}/></Col>
          <Col xs="3">
            <Button className='mt-3' color="light" disabled={isDisabled} type="submit">
            {t('execution.dealPopOver.enter')}
          </Button>
          </Col>
        </Row>
        <div className='mt-3'>
            <ReactTable
            data={comment}
            columns={columns}
            defaultPageSize={5}
            LoadingComponent={TableSpinner}
            filterable={true}
            defaultFilterMethod={(filter, row) => {
              const a = String(row[filter.id]).toLowerCase().replace(/\s\s+/g, ' ')
              const b = filter.value.toLowerCase()
              return a.includes(b)
            }}
            {...translations(t)}
          />
        </div>
      </section>
    </Form>
  )
}
