import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactTable from 'react-table'
import { Button } from 'reactstrap'
import moment from 'moment'
import { translations } from 'helpers/react-table'
import { decimalFormat } from 'helpers/formater'
import { getVisibleContracts } from 'reducers/contracts'
import {
  fetchContractsTransactions,
  getTableData,
  getIsLoading,
  getSelectedContractId
} from 'store/deals'
import { TableSpinner } from 'components/common/Spinner'
import { isNil } from 'lodash'

const headerStyle = {
  textTransform: 'uppercase',
}

const columnLeft = {
  headerStyle,
}

const columnRight = {
  headerStyle,
  Cell: (row) => <div style={{ textAlign: 'right' }}>{row.value}</div>,
}

const validText = (status) => {
  const { t } = useTranslation()
  if(status == 1)
    return t('execution.transactionStatus.pending')
  if (status == 2)
    return t('execution.transactionStatus.validated')
  if (status == 3)
    return t('execution.transactionStatus.invalidated')
}

const DealsTable = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visibleContracts = useSelector(getVisibleContracts)
  let data = useSelector(getTableData)
  data = data.filter(function (element) {
      return element !== undefined
  })
  const isLoading = useSelector(getIsLoading)

  const selectedContractId = useSelector(getSelectedContractId)
  const [selected, setSelected] = React.useState(null)

  useEffect(() => {
      const filtered = data.filter((item) => item && Number(item.visualize.contractId) === selectedContractId)
      if (filtered.length > 0 && !filtered.some((item) => item.id === selected)) {
        setSelected(filtered[0]?.id)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selected])

  useEffect(() => {
    dispatch(fetchContractsTransactions(visibleContracts))
  }, [dispatch, visibleContracts])

  const columns = [
    {
      Header: t('deals.table.contract'),
      accessor: 'contract',
      ...columnLeft,
      Cell: (row) => (
        <div>
          <span style={{ marginRight: 10 }}>
            {row.original.nrjId === 2 && <i className="icon-elec" />}
            {row.original.nrjId === 1 && <i className="icon-gaz" />}
          </span>
          {row.value}
        </div>
      ),
    },
    {
      Header: t('deals.table.businessUnit'),
      accessor: 'businessUnit',
      ...columnLeft,
      width: 120,
    },
    {
      Header: t('deals.table.parcel'),
      accessor: 'parcel',
      ...columnLeft,
      width: 80,
    },
    {
      Header: t('deals.table.product'),
      accessor: 'product',
      ...columnLeft,
    },
    {
      Header: t('deals.table.date'),
      accessor: 'date',
      width: 120,
      ...columnLeft,
      Cell: (row) => <div style={{ textAlign: 'center' }}>{row.original.dateFormatted}</div>,
      filterMethod: (filter, row) => {
        const a = String(row._original.dateFormatted)
        const b = filter.value
        return a.includes(b)
      },
      sortMethod: (a, b, desc) => {
        // force null and undefined to the bottom
        a = a === null || a === undefined ? '' : moment(a).format('x')
        b = b === null || b === undefined ? '' : moment(b).format('x')
        return a > b ? 1 : a < b ? -1 : 0
      },
    },
    {
      Header: t('deals.table.volume'),
      accessor: 'volume',
      width: 120,
      ...{ headerStyle },
      sortable: false,
      filterable: false,
      Cell: (row) => (
        <div style={{ textAlign: 'right' }}>
          {`${decimalFormat(row.value.value)} ${row.value.unit}`}
        </div>
      ),
    },
    {
      Header: t('deals.table.price'),
      accessor: 'price',
      ...columnRight,
      sortable: false,
      filterable: false,
      Cell: (row) => (
        <div style={{ textAlign: 'right' }}>
          {`${decimalFormat(row.value.value, { digitCount: 4, force: true })} ${row.value.unit}`}
        </div>
      ),
    },
    {
      Header: t('execution.transactionView.internalValid'),
      accessor: 'internalValidation.Status',
      ...columnRight,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row)=> {
        if (filter.value === "all"){
          return true;
        }
        return row[filter.id] == filter.value
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">{t('execution.transactionStatus.all')}</option>
          <option value="1">{t('execution.transactionStatus.pending')}</option>
          <option value="2">{t('execution.transactionStatus.validated')}</option>
          <option value="3">{t('execution.transactionStatus.invalidated')}</option>
        </select>
      ),
      Cell: (row) => (
        <div style={{ textAlign: 'right' }}>
          {validText(row.value)}
        </div>
      ),
    },
    {
      Header: t('execution.transactionView.clientValid'),
      accessor: 'clientValidation.Status',
      ...columnRight,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row)=> {
        if (filter.value === "all"){
          return true;
        }
        return row[filter.id] == filter.value
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">{t('execution.transactionStatus.all')}</option>
          <option value="1">{t('execution.transactionStatus.pending')}</option>
          <option value="2">{t('execution.transactionStatus.validated')}</option>
          <option value="3">{t('execution.transactionStatus.invalidated')}</option>
        </select>
      ),
      Cell: (row) => (
        <div style={{ textAlign: 'right' }}>
          {validText(row.value)}
        </div>
      ),
    },
    {
      Header: null,
      accessor: 'visualize',
      width: 50,
      headerStyle,
      filterable: false,
      sortable: false,
      Cell: (row) => {
        const { contractId, lotId, year, id } = row.value
        return (
          <div style={{ textAlign: 'center' }}>
            <Button
              className="btn-table active"
              onClick={() => {
                setSelected(row.original.id)
                props.onVisualize(Number(contractId), Number(lotId), year, Number(id))
              }}
            >
              <i className="icon-eye" style={{ cursor: 'default' }} />
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <div className="deals-table">
      <ReactTable
        loading={isLoading}
        data={data}
        columns={columns}
        defaultPageSize={20}
        className="-striped"
        LoadingComponent={TableSpinner}
        filterable={true}
        defaultFilterMethod={(filter, row) => {
          const a = String(row[filter.id]).toLowerCase().replace(/\s\s+/g, ' ')
          const b = filter.value.toLowerCase()
          return a.includes(b)
        }}
        sortable={true}
        defaultSorted={[
          {
            id: 'contract',
            desc: false,
          },
          {
            id: 'parcel',
            desc: false,
          },
          {
            id: 'product',
            desc: false,
          },
          {
            id: 'date',
            desc: false,
          },
        ]}
        {...translations(t)}
        getTrProps={(state, rowInfo) => {
          return {
            style: {
              background: rowInfo?.original.id === selected ? 'rgba(33, 33, 90, .2)' : 'unset',
            },
          }
        }}
      />
    </div>
  )
}

export default DealsTable
