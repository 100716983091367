import variables from 'assets/css/_variables.module.scss'

export interface Theme {
  fontFamily: string
  blue: string
  gold: string
  red: string
  orange: string
  yellow: string
  cyan: string
  purple: string

  gray100: string
  gray200: string
  gray300: string
  gray400: string
  gray500: string
  gray600: string
  gray700: string
  gray800: string
  gray900: string

  primary: string
  secondary: string
  success: string
  info: string
  warning: string
  danger: string
  light: string
  dark: string

  elec: string
  gas: string
  spacing: string
}

export const theme: Theme = variables as any
