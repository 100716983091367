/** @jsxImportSource @emotion/react */
import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from 'images/gazelEnergieNew.svg'
import { ReactComponent as LogoGazel } from 'images/gazel-energie-logo.svg'

export const DefaultLogo = () => {
  return (
    <div
      css={(theme) => ({
        fontFamily: 'Merlo, Muli, sans-serif',
        color: '#e9ecef', //theme.gray200,

        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '>div': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        },

        a: {
          color: '#e9ecef', //theme.gray200,
          '&:hover': {
            textDecoration: 'none',
            color: '#fff',
          },
        },

        '.logo-eflex': {
          fontSize: '3.8rem',
          lineHeight: '1.2',
        },

        '.logo-eleneo': {
          fontSize: 10,
          svg: {
            width: 'calc(1.2rem * 3.6)',
            height: '1.2rem',
            fill: '#6c757d', // theme.gray600
            marginBottom: 3,
            verticalAlign: 'bottom',
            marginLeft: '.5rem',
            marginRight: '.5rem',
          },
          '.svg-logo-eleneo': {
            width: '100%',
            height: 'auto',
          },
        },
      })}
    >

      <a href="https://gazelenergie.fr">
        <Logo
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </a>
      <div style={{ 'margin-top': '-80px' }}>
        <Link to="/" className="logo-eflex">
          <LogoGazel
            className="svg-logo-eleneo"
            style={{
              width: '100%',

              height: 'auto',
            }}
          />
        </Link>
      </div>
    </div>
  )
}
