const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS'

export const setFilters = (filter) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_FILTERS,
    filter,
  })
}

const searchFilters = (
  state = {
    energyType: [1, 2],
    countries: [],
    providers: [],
    startYear: new Date().getFullYear(),
    endYear: null,
    coverage: [0, 1],
    configuration: [0, 1, 2],
    organisations: []
  },
  action,
) => {
  switch (action.type) {
    case SET_SEARCH_FILTERS:
      return action.filter
    default:
      return state
  }
}

export default searchFilters
