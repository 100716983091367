/** @jsxImportSource @emotion/react */
import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ReactNotification from 'react-notifications-component'

import { ErrorBoundary, UserPanel, Version } from 'commons'

// Components
import { SlidePanel } from 'components/common/SlidePanel'
import SideBar from '../sidebar/Sidebar'
import SearchPanel from '../searchPanel/SearchPanel'
import TopNav from '../topnav/TopNav'
import Views from '../route/Views'

const Template = () => {
  const [searchPanelOpen, setSearchPanelOpen] = React.useState(false)
  const [userPanelOpen, setUserPanelOpen] = React.useState(false)
  const closeUserPanel = () => setUserPanelOpen(false)

  return (
    <>
      <ReactNotification />

      <Container fluid>
        <Row>
          <Col className="sidebar-col" tag="aside">
            <SideBar setSearchPanelOpen={setSearchPanelOpen} searchPanelOpen={searchPanelOpen} />
          </Col>

          <Col className="main-col">
            <Row>
              <div
                css={{
                  width: '100%',
                  height: '100vh',
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div css={{ flex: 'none' }}>
                  <TopNav userPanelOpen={userPanelOpen} setUserPanelOpen={setUserPanelOpen} />
                </div>

                <div
                  css={{
                    padding: '0 15px',
                    flexGrow: 1,
                    overflowY: 'auto',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div css={{ flexGrow: 1 }}>
                    <ErrorBoundary>
                      <Views />
                    </ErrorBoundary>
                  </div>

                  <div css={{ flex: 'none' }}>
                    <Version />
                  </div>
                </div>
              </div>
            </Row>
          </Col>

          <SearchPanel searchPanelOpen={searchPanelOpen} setSearchPanelOpen={setSearchPanelOpen} />
        </Row>
      </Container>

      <SlidePanel isOpen={userPanelOpen} close={closeUserPanel} width={480} position="right">
        <UserPanel done={closeUserPanel} />
      </SlidePanel>
    </>
  )
}

export default Template
