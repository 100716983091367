import get from 'lodash/get'

const ensureArray = (x) => (Array.isArray(x) ? x : [x])

export const isAdmin = (state) => {
  const roles = ensureArray(get(state, 'oidc.user.profile.role', []))
  return roles.some((role) => ['superuser', 'admin:eflex'].includes(role))
}

export const isSuperUser = (state) => {
  const roles = ensureArray(get(state, 'oidc.user.profile.role', []))
  return roles.includes('superuser')
}

export const isQualityUser = (state) => {
  const roles = ensureArray(get(state, 'oidc.user.profile.role', []))
  return roles.some((role) => ['gazelenergie:quality'].includes(role))
}