import { combineReducers } from 'redux'
import flatten from 'lodash/flatten'
import pick from 'lodash/pick'

import {
  FETCH_CONTRACT_PARCELS_SUCCESS,
  FETCH_CONTRACT_PARCEL_DETAIL_SUCCESS,
  RESET_CONTRACTS_PARCELS,
  FETCH_PARCELS_NAMES_SUCCESS,
  FETCH_PARCEL_TARIF_SUCCESS,
} from '../actions'

const contractParcels = (state = {}, action) => {
  let newState
  switch (action.type) {
    case RESET_CONTRACTS_PARCELS:
      return []
    case FETCH_CONTRACT_PARCELS_SUCCESS:
      newState = { ...state }
      newState[action.contractId] = action.parcels
      return newState
    default:
      return state
  }
}

const parcelDetail = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_CONTRACT_PARCEL_DETAIL_SUCCESS:
      newState = { ...state }
      newState[action.parcel.id] = action.parcel
      return newState
    default:
      return state
  }
}

const names = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PARCELS_NAMES_SUCCESS:
      return {
        ...state,
        [action.contractId]: action.parcels,
      }
    default:
      return state
  }
}

const parcelTarif = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PARCEL_TARIF_SUCCESS:
      return {
        ...state,
        [action.parcel.LotId]: action.parcel,
      }
    default:
      return state
  }
}

export default combineReducers({
  contractParcels,
  parcelDetail,
  names,
  parcelTarif,
})

export const getContractParcels = (contractId) => (state) =>
  state.parcels.contractParcels[contractId] || []

export const getAllContractParcels = (state) =>
  state.quotations.parcelAndQuotationsByPeriod.quotations || []
export const getParcelDetail = (state, parcelId) => state.parcels.parcelDetail[parcelId] || []

export const getParcelNames = (state) =>
  flatten(Object.values(state.parcels.names)).reduce(
    (acc, parcel) => ({
      ...acc,
      [parcel.id]: parcel.nom,
    }),
    {},
  )

export const getParcelsByContract = (selection) => (state) =>
  pick(
    state.parcels.contractParcels,
    selection.map((id) => id.toString()),
  )

export const getParcelTarifs = (parcelId) => (state) => state.parcels.parcelTarif[parcelId] || []


