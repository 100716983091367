import React, {useState} from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Row, Col  } from 'reactstrap'
import { DealsCreateForm } from './DealsCreateForm'
import { addNotification } from 'notification'
import { DealCommentForm } from './DealCommentForm'
import { DealsValidationForm } from './DealsValidationForm'
import { isNil } from 'lodash'
import moment from 'moment'
import { fetchTransaction, addTransaction, updateTransaction, getTransactionComments as apiGetComments, createTransactionComment as apiInsertComment, deleteTransaction } from 'api/transactions'
import { createClientValidation, updateClientValidation, createInternalValidation, updateInternalValidation } from 'api/transactions'

export const DealsCreateDialog = ({ transaction, quotations, contractId, contractParcels, selectedParcelId, isValidationTab, done = () => {}, fetchNewData = () => {}  }) => {
  const { t } = useTranslation()
  const [comments, setComments] = useState(null)
  const [initialDealValue, setInitialDealValue] = useState(null)
  const [initialCommentValue, setInitialCommentValue] = useState(null)
  const [initialValidationValue, setInitialValidationValue] = useState(null)

  React.useEffect(() => {
    if(contractId){
      const commentValues = {
        ContractId: contractId,
        Transaction: transaction,
        Comment: {
          Id: null,
          Content: null,
          TransactionId : transaction?.id
        },
        Comments: null
      }
      
      setInitialCommentValue(commentValues)
    }
  }, [contractId, transaction])


  React.useEffect(() => {
    if(contractId && selectedParcelId && quotations){
      const values = {
        ContractId: contractId,
        Transaction: {
          id: transaction?.id,
          idCotation: transaction?.idCotation,
          TrsVolume: transaction?.TrsVolume,
          TrsVolumeUnite: transaction?.TrsVolumeUnite,
          TrsDate: transaction?.TrsDate,
          TrsPrix: transaction?.TrsPrix,
          TrsUnite: transaction?.TrsUnite,
          TrsPrixPeak: transaction?.TrsPrixPeak,
          idCotationPeak: transaction?.idCotationPeak,
          HasPeak: transaction?.HasPeak,
          TypePtf: transaction?.TypePtf,
          idLot: selectedParcelId,
          validated: transaction?.ClientValidation.Status == 2
        },
        QuotationOptions: quotations,
        ContractParcels: contractParcels,
        SelectedParcelId: selectedParcelId
      }
  
      setInitialDealValue(values)
    }
  }, [contractId, transaction, selectedParcelId, quotations])

  React.useEffect(() => {
    if(contractId){
      const validationValues = {
        ContractId: contractId,
        Transaction: transaction,
        ClientValidation: {
          Id : transaction?.ClientValidation.Id,
          TransactionId: transaction?.id,
          Status: transaction?.ClientValidation.Status
        },
        InternalValidation: {
          Id : transaction?.InternalValidation.Id,
          TransactionId: transaction?.id,
          Status: transaction?.InternalValidation.Status
        },
      }

      setInitialValidationValue(validationValues)
    }
  }, [contractId, transaction])

  const submit = React.useCallback(
    async (values, actions) => {
      try {
        if(isNil(values.Transaction.id)){
          if(isNil(values.Transaction.TrsDate))
            values.Transaction.TrsDate = moment()
          const created = await addTransaction(selectedParcelId, values.Transaction)
          values.Transaction.id = created.id
        }
        else {
          await updateTransaction(selectedParcelId, values.Transaction)
          values.Transaction = await fetchTransaction(values.Transaction.idLot, values.Transaction.id)
        }
        done()
        fetchNewData(selectedParcelId)
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('contract.site.createErrorTitle'),
          message: (error).toString(),
        })
      }
    },
    [t],
  )
  const commentSubmit = React.useCallback(
    async (commentValues, actions) => {
      try {
          await apiInsertComment(commentValues.Comment)
          commentValues.Comments = await apiGetComments(commentValues.Comment.TransactionId)
          setComments(commentValues.Comments)
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('contract.site.createErrorTitle'),
          message: (error).toString(),
        })
      }
    },
    [t],
  )

  const hasClientValidationChanged = (values) => {
    return values.ClientValidation && values.Transaction &&
      values.ClientValidation.Status != values.Transaction.ClientValidation.Status
  }

  const hasInternalValidationChanged = (values) => {
    return values.InternalValidation && values.Transaction &&
    values.InternalValidation.Status != values.Transaction.InternalValidation.Status
  }

  const validationSubmit = React.useCallback(
    async (values, actions) => {
      try {
        if(hasClientValidationChanged(values) && values.ClientValidation.Id > 0){
          await updateClientValidation(values.ClientValidation)
       }
        if(hasClientValidationChanged(values) && values.ClientValidation.Id == 0){
          await createClientValidation(values.ClientValidation)
        }
        if(hasInternalValidationChanged(values) && values.InternalValidation.Id > 0){
           await updateInternalValidation(values.InternalValidation)
        }
        if(hasInternalValidationChanged(values) && values.InternalValidation.Id == 0){
          await createInternalValidation(values.InternalValidation)
        }

        done()
        fetchNewData(selectedParcelId)
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('contract.site.createErrorTitle'),
          message: (error).toString(),
        })
      }
    },
    [t],
  )

  const getNewComments = 
    async () => {
      try {
        const newComments= await apiGetComments(transaction.id)
        setComments(newComments)
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('contract.site.createErrorTitle'),
          message: (error).toString(),
        })
      }
    }

  const deleteDeal = 
    async () => {
      try {
        await deleteTransaction(selectedParcelId, initialDealValue.Transaction.id)
        done()
        fetchNewData(selectedParcelId)
      } catch (error) {
        addNotification({
          type: 'danger',
          title: t('contract.site.createErrorTitle'),
          message: (error).toString(),
        })
      }
    }
  
  return (
    <div>
    <hr />
    <Row>
      <Col>
        <Formik
          enableReinitialize
          initialValues={initialDealValue}
          onSubmit={submit}
        >
          {(props) => <DealsCreateForm isLoading={status === 'loading'} onDelete={deleteDeal} isValidationTab={isValidationTab} {...props} />}
        </Formik>
        {transaction && (
        <Formik 
          enableReinitialize
          initialValues={initialValidationValue}
          onSubmit={validationSubmit}
        >
          {(props) => <DealsValidationForm isLoading={status === 'loading'} {...props} />}
        </Formik>
        )}
      </Col>
      {transaction && (
      <Col>
        <Formik
          enableReinitialize
          initialValues={initialCommentValue}
          onSubmit={commentSubmit}
        >
          {(props) => <DealCommentForm isLoading={status === 'loading'} transactionId={transaction?.id} comments={comments} onChange={getNewComments} {...props} />}
        </Formik>
      </Col>
      )}
    </Row>
    </div>
  )
}
