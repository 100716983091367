import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import DealsTable from 'components/deals/DealsTable'
import Transaction from './Transaction'
import { selectContract } from 'store/deals'
import { calculateStartEndDates }from 'helpers/dateHelper'
import { fetchContractTransactions, fetchLotTransactions, fetchClientTransactions } from 'actions/transactions'

const Admin = () => {
  const dispatch = useDispatch()
  const [year, setYear] = useState(null)
  const [lotId, setLotId] = useState(null)
  const [contractId, setContractId] = useState(0)
  const [transactionId, setTransactionId] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  
  const onVisualize = useCallback(
    (contractId, lotId, year, transactionId) => {
      dispatch(selectContract(0))
      //if the deal doesn't have proper year info, don't open the modal
      if(year === 1)
        return

        dispatch(selectContract(contractId))
        setContractId(contractId)
        setYear(year)
        setLotId(lotId)
        setTransactionId(transactionId)
        setIsModalOpen(true)
    },
    [dispatch]
  )

  const close = useCallback(()=>{
    setIsModalOpen(false)
  })

  const fetchNewData = useCallback(() => {
    if(lotId && contractId){
      dispatch(fetchContractTransactions(contractId))
      dispatch(fetchLotTransactions(lotId))
      dispatch(fetchClientTransactions())
  } })

  return (
    <div>
      <section>
        <DealsTable onVisualize={onVisualize} />
      </section>
        <Transaction
        contractId={contractId}
        year={year}
        lotId={lotId}
        transactionId={transactionId}
        close={close}
        isModalOpen={isModalOpen}
        fetchNewData={fetchNewData}
        isValidationTab={true}
        />
    </div>
  )
}

export default Admin
