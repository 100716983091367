import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Logout, Callback, AccessDenied, SilentRedirectPage, userManager } from 'commons'

import * as env from 'helpers/env'

import Template from '../template/Template'
import { customerTheme } from 'helpers/themes'

class Root extends Component {
  state = {
    isRedirecting: false,
  }

  componentDidUpdate(oldState) {
    if (
      this.props.location.pathname &&
      !this.props.location.pathname.startsWith('/callback') &&
      !this.props.location.pathname.startsWith('/silent_renew')
    ) {
      if (
        (!this.props.user || (!this.props.user && this.props.user.expired === true)) &&
        this.props.isLoadingUser === false
      ) {
        if (this.state.isRedirecting === false) {
          this.setState({ isRedirecting: true })
          userManager.signinRedirect()
        }
      }
    }
  }

  isAllowed() {
    if (this.props.user && this.props.user.profile && this.props.user.profile.role) {
      if (Array.isArray(this.props.user.profile.role)) {
        return this.props.user.profile.role.some((role) => env.requiredRoles.includes(role))
      } else {
        return env.requiredRoles.includes(this.props.user.profile.role)
      }
    }

    return false
  }

  render() {
    return (
      <div className={this.props.theme}>
        {this.props.user && !this.props.user.expired ? (
          this.isAllowed() ? (
            <Switch>
              <Route exact path="/silent_renew" component={SilentRedirectPage} />
              <Route exact path="/" render={() => <Redirect to="/exec" push />} />
              <Route path="/logout" component={Logout} />
              <Route path="/callback" component={Callback} />
              <Route path="/:path" component={Template} />
            </Switch>
          ) : (
            <Switch>
              <Route path="/" component={AccessDenied} />
            </Switch>
          )
        ) : (
          <Switch>
            <Route exact path="/silent_renew" component={SilentRedirectPage} />
            <Route path="/" component={Callback} />
          </Switch>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingUser: state.oidc.isLoadingUser,
    loggedIn: !!(state.oidc.user && !state.oidc.user.expired),
    user: state.oidc.user,
    theme: customerTheme(state),
  }
}

Root.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
}

export default withRouter(connect(mapStateToProps)(Root))
