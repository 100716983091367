import {
  fetchContractTransactions as apiFetchContractTransactions,
  fetchContractsTransactions as apiFetchContractsTransactions,
  fetchLotTransactions as apiFetchLotTransactions,
  fetchLotPeriodeTransactions as apiFetchLotPeriodeTransactions,
  fetchLotQuotationTransactions as apiFetchLotQuotationTransactions,
  addTransaction as apiAddTransaction,
  updateTransaction as apiUpdateTransaction,
  deleteTransaction as apiDeleteTransaction,
  validateTransaction as apiValidateTransaction,
  getTransactionComments as apiFetchTransactionComments,
  deleteTransactionComments as apiDeleteTransactionComments,
  fetchClientTransactions as apiFetchClientTransactions
} from '../api/transactions'

import {
  FETCH_CONTRACT_TRANSACTIONS_REQUEST,
  FETCH_CONTRACT_TRANSACTIONS_FAILURE,
  FETCH_CONTRACT_TRANSACTIONS_SUCCESS,
  FETCH_LOT_TRANSACTIONS_REQUEST,
  FETCH_LOT_TRANSACTIONS_FAILURE,
  FETCH_LOT_TRANSACTIONS_SUCCESS,
  FETCH_LOT_PERIODE_TRANSACTIONS_REQUEST,
  FETCH_LOT_PERIODE_TRANSACTIONS_FAILURE,
  FETCH_LOT_PERIODE_TRANSACTIONS_SUCCESS,
  FETCH_LOT_QUOTATION_TRANSACTIONS_REQUEST,
  FETCH_LOT_QUOTATION_TRANSACTIONS_FAILURE,
  FETCH_LOT_QUOTATION_TRANSACTIONS_SUCCESS,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILURE,
  DELETE_TRANSACTION_REQUEST,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILURE,
  VALIDATE_TRANSACTION_REQUEST,
  VALIDATE_TRANSACTION_SUCCESS,
  VALIDATE_TRANSACTION_FAILURE,
  FETCH_TRANSACTION_COMMENTS_REQUEST,
  FETCH_TRANSACTION_COMMENTS_SUCCESS,
  FETCH_TRANSACTION_COMMENTS_FAILURE,
  DELETE_TRANSACTION_COMMENT_REQUEST,
  DELETE_TRANSACTION_COMMENT_SUCCESS,
  DELETE_TRANSACTION_COMMENT_FAILURE,
  FETCH_CONTRACTS_TRANSACTIONS_REQUEST,
  FETCH_CONTRACTS_TRANSACTIONS_SUCCESS,
  FETCH_CONTRACTS_TRANSACTIONS_FAILURE,
  FETCH_CLIENT_TRANSACTIONS_REQUEST,
  FETCH_CLIENT_TRANSACTIONS_SUCCESS,
  FETCH_CLIENT_TRANSACTIONS_FAILURE
} from './'

export const fetchContractTransactions = (contractId, quotationId) => async (dispatch) => {
  dispatch({
    type: FETCH_CONTRACT_TRANSACTIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_CONTRACT_TRANSACTIONS_SUCCESS,
      contractId,
      transactions: await apiFetchContractTransactions(contractId, quotationId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_CONTRACT_TRANSACTIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchContractsTransactions =
  (contractIds, startDate, endDate) => async (dispatch) => {
    dispatch({
      type: FETCH_CONTRACTS_TRANSACTIONS_REQUEST,
      contractIds,
    })
    try {
      dispatch({
        type: FETCH_CONTRACTS_TRANSACTIONS_SUCCESS,
        quotations: await apiFetchContractsTransactions(contractIds, startDate, endDate),
        contractIds,
        startDate,
        endDate,
      })
    } catch (e) {
      dispatch({
        type: FETCH_CONTRACTS_TRANSACTIONS_FAILURE,
        message: e.message || 'Something went wrong.',
      })
    }
  }

export const fetchClientTransactions = () => async (dispatch) => {
    dispatch({
      type: FETCH_CLIENT_TRANSACTIONS_REQUEST,
    })
    try {
      dispatch({
        type: FETCH_CLIENT_TRANSACTIONS_SUCCESS,
        transactions: await apiFetchClientTransactions(),
      })
    } catch (e) {
      dispatch({
        type: FETCH_CLIENT_TRANSACTIONS_FAILURE,
        message: e.message || 'Something went wrong.',
      })
    }
  }

export const fetchLotTransactions = (lotId) => async (dispatch) => {
  dispatch({
    type: FETCH_LOT_TRANSACTIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_LOT_TRANSACTIONS_SUCCESS,
      lotId,
      transactions: await apiFetchLotTransactions(lotId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_LOT_TRANSACTIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchLotPeriodeTransactions = (lotId, debut, fin) => async (dispatch) => {
  dispatch({
    type: FETCH_LOT_PERIODE_TRANSACTIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_LOT_PERIODE_TRANSACTIONS_SUCCESS,
      lotId,
      transactions: await apiFetchLotPeriodeTransactions(lotId, debut, fin),
    })
  } catch (e) {
    dispatch({
      type: FETCH_LOT_PERIODE_TRANSACTIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchLotQuotationTransactions = (lotId, cotId) => async (dispatch) => {
  dispatch({
    type: FETCH_LOT_QUOTATION_TRANSACTIONS_REQUEST,
  })
  try {
    dispatch({
      type: FETCH_LOT_QUOTATION_TRANSACTIONS_SUCCESS,
      lotId,
      cotId,
      transactions: await apiFetchLotQuotationTransactions(lotId, cotId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_LOT_QUOTATION_TRANSACTIONS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const addTransaction = (lotId, contractId, transaction) => async (dispatch) => {
  dispatch({
    type: ADD_TRANSACTION_REQUEST,
  })
  try {
    dispatch({
      type: ADD_TRANSACTION_SUCCESS,
      lotId,
      contractId,
      transaction: await apiAddTransaction(lotId, transaction),
    })
  } catch (e) {
    dispatch({
      type: ADD_TRANSACTION_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const updateTransaction = (lotId, contractId, transaction) => async (dispatch) => {
  dispatch({
    type: UPDATE_TRANSACTION_REQUEST,
  })
  try {
    dispatch({
      type: UPDATE_TRANSACTION_SUCCESS,
      lotId,
      contractId,
      transaction: await apiUpdateTransaction(lotId, transaction),
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: UPDATE_TRANSACTION_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const deleteTransaction =
  (lotId, contractId, transactionId, message) => async (dispatch) => {
    if (!window.confirm(message)) return

    dispatch({
      type: DELETE_TRANSACTION_REQUEST,
      lotId,
      contractId,
      transactionId,
    })
    try {
      const rq = await apiDeleteTransaction(lotId, transactionId)
      if (rq.status === 200) {
        dispatch({
          type: DELETE_TRANSACTION_SUCCESS,
          lotId,
          contractId,
          transactionId,
        })
      }
    } catch (e) {
      console.error(e)
      dispatch({
        type: DELETE_TRANSACTION_FAILURE,
        message: e.message || 'Something went wrong.',
      })
    }
  }

export const validateTransaction = (dealId, message) => async (dispatch) => {
  if (!window.confirm(message)) return

  dispatch({
    type: VALIDATE_TRANSACTION_REQUEST,
    dealId,
  })
  try {
    const rq = await apiValidateTransaction(dealId)
    if (rq.status === 200) {
      dispatch({
        type: VALIDATE_TRANSACTION_SUCCESS,
        dealId,
      })
    }
  } catch (e) {
    console.error(e)
    dispatch({
      type: VALIDATE_TRANSACTION_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const fetchTransactionComments = (transactionId) => async (dispatch) => {
  dispatch({
    type: FETCH_TRANSACTION_COMMENTS_REQUEST,
    transactionId
  })
  try {
    dispatch({
      type: FETCH_TRANSACTION_COMMENTS_SUCCESS,
      transactionId,
      comments: await apiFetchTransactionComments(transactionId),
    })
  } catch (e) {
    dispatch({
      type: FETCH_TRANSACTION_COMMENTS_FAILURE,
      message: e.message || 'Something went wrong.',
    })
  }
}

export const deleteTransactionComment =
  (commentId, message) => async (dispatch) => {
    if (!window.confirm(message)) return

    dispatch({
      type: DELETE_TRANSACTION_COMMENT_REQUEST,
      commentId,
    })
    try {
      const rq = await apiDeleteTransactionComments(commentId)
      if (rq.status === 200) {
        dispatch({
          type: DELETE_TRANSACTION_COMMENT_SUCCESS,
          commentId
        })
      }
    } catch (e) {
      console.error(e)
      dispatch({
        type: DELETE_TRANSACTION_COMMENT_FAILURE,
        message: e.message || 'Something went wrong.',
      })
    }
  }