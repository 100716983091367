import { Suspense, lazy } from 'react'
import { Spinner } from 'reactstrap'
import { ErrorBoundary } from 'react-error-boundary'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isAdmin as getIsAdmin } from 'selectors/user'
import { ErrorFallback } from 'components/common/ErrorBoundary'

import Admin from 'components/admin/Admin'

const Portfolio = lazy(() => import('components/portfolio/Portfolio'))
const ContractsList = lazy(() => import('components/contracts/ContractsList'))
const Execution = lazy(() => import('components/execution/Execution'))
const Market = lazy(() => import('components/market/Market'))
const Transactions = lazy(() => import('components/transactions/Transactions'))
const Deals = lazy(() => import('components/deals/Deals'))
const Alerts = lazy(() => import('components/alerts/Alerts'))
const PerformancePage = lazy(() => import('components/performance/PerformancePage'))

const Error = () => (
  <div className="mt-4 ml-2">
    <ErrorFallback />
  </div>
)
const Loader = () => (
  <div className="mt-5 d-flex justify-content-center">
    <Spinner color="primary" />
  </div>
)

const Views = () => {
  const isAdmin = useSelector(getIsAdmin)

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/exec/" component={Execution} />
          <Route path="/market/" component={Market} />
          <Route path="/strategy/:ctrids?" component={ContractsList} />
          <Route path="/deals/" component={Deals} />
          <Route path="/alerts/" component={Alerts} />
          <Route path="/admin/" component={Admin} />
          <Route path="/transactions/" component={Transactions} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  )
}

export default Views
