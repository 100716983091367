import React from 'react'
import PropTypes from 'prop-types'
import { TabContent, TabPane } from 'reactstrap'

import SidebarCurrent from './SidebarCurrent'
import EmptySelection from './EmptySelection'

const SidebarTabs = ({ activeTab, toggleSearchPanel, searchPanelOpen }) => (
  <TabContent activeTab={activeTab}>
    <TabPane tabId="SELECTION" className="current-selection-tab">
      <SidebarCurrent searchPanelOpen={searchPanelOpen} toggleSearchPanel={toggleSearchPanel} />
    </TabPane>
    <EmptySelection />
  </TabContent>
)

SidebarTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  searchPanelOpen: PropTypes.bool.isRequired,
  toggleSearchPanel: PropTypes.func.isRequired,
}

export default SidebarTabs
