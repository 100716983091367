import { FETCH_FILTERS_PARAM_SUCCESS } from '../actions'

const organisations = (state = [], action) => {
  switch (action.type) {
    case FETCH_FILTERS_PARAM_SUCCESS:
      return action.organisations
    default:
      return state
  }
}

export default organisations

export const getOrganisations = (state) => state.organisations
