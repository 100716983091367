import { superFetch } from 'commons'

export const fetchContracts = () => {
  return superFetch({ url: 'contrats?gazelEnergie=true' })
}

export const fetchContractsDetail = (contractIds) => {
  return superFetch({
    url: 'contrats/search',
    method: 'POST',
    body: contractIds,
  })
}

export const fetchContractCoverage = (contractIds, startDate, endDate) => {
  const query = {
    debut: startDate.format('DD/MM/YYYY'),
    fin: endDate.format('DD/MM/YYYY'),
    contrats: contractIds,
  }

  return superFetch({
    url: 'contrats/couverture/agregee',
    method: 'POST',
    body: query,
  })
}

// Expected dates are moment.js dates here
export const fetchMonthlyContractCoverage = (contractIds, startDate, endDate) => {
  const query = {
    debut: startDate.format('DD/MM/YYYY'),
    fin: endDate.format('DD/MM/YYYY'),
    contrats: contractIds,
    //idLot
  }

  return superFetch({
    url: 'contrats/couverture/mensuel',
    method: 'POST',
    body: query,
  })
}

// Expected dates are moment.js dates here
export const fetchMonthlyPrices = (contractIds, startDate, endDate) => {
  const query = {
    debut: startDate.format('DD/MM/YYYY'),
    fin: endDate.format('DD/MM/YYYY'),
    contrats: contractIds,
  }

  return superFetch({
    url: 'contrats/prix/mensuel',
    method: 'POST',
    body: query,
  })
}

export const fetchContractCoverageRecap = (contractIds, startDate, endDate) => {
  const query = {
    debut: startDate.format('DD/MM/YYYY'),
    fin: endDate.format('DD/MM/YYYY'),
    contrats: contractIds,
  }

  return superFetch({
    url: 'contrats/couverture/synthese',
    method: 'POST',
    body: query,
  })
}

export const fetchStrategyFollowup = (contractIds, startDate, endDate, idLot) => {
  const query = {
    debut: startDate.format('DD/MM/YYYY'),
    fin: endDate.format('DD/MM/YYYY'),
    contrats: contractIds,
    ...(idLot && { idLot }),
  }

  return superFetch({
    url: 'couvertures/plans/synthese',
    method: 'POST',
    body: query,
  })
}

export const fetchContractConfigStatus = (contractIds) => {
  return superFetch({
    url: 'contrats/config/status',
    method: 'POST',
    body: contractIds,
  })
}

export const fetchMonthlyCoverageFollowup = (contractIds, startDate, endDate) => {
  const query = {
    debut: startDate.format('DD/MM/YYYY'),
    fin: endDate.format('DD/MM/YYYY'),
    contrats: contractIds,
  }
  return superFetch({
    url: 'contrats/couverture/synthesemensuelle',
    method: 'POST',
    body: query,
  })
}

export const callContractsValorisation = (contractIds) => {
  return superFetch({
    url: 'valorisations',
    method: 'POST',
    body: contractIds,
  })
}

export const fetchContractsList = (uuid, dateDebut, dateFin, perimetre, lanId) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  return superFetch({
    url: `listecontratvuectr?date_debut=${dateDebut}&date_fin=${dateFin}&lanId=${lanId}`,
    method: 'POST',
    body: perimetre,
    uuid: uuid,
    returnType: 'response',
  })
}

export const fetchContract = (uuid, ctrid, dateDebut, dateFin, lanId) => {
  if (!ctrid) {
    return Promise.resolve()
  }

  return superFetch({
    url: `contratvuectr?ctrid=${ctrid}&date_debut=${dateDebut}&date_fin=${dateFin}&lanId=${lanId}`,
    uuid: uuid,
    returnType: 'response',
  })
}

export const fetchContractArenh = ({ contractId, parcelId }) =>
  superFetch({
    url: `contrats/${contractId}/lots/${parcelId}/arenh`,
  })

export const updateContractArenh = ({ contractId, body }) =>
  superFetch({
    url: `contrats/${contractId}/arenh`,
    method: 'POST',
    body,
  })
